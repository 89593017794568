<template>
  <div class="accordion" id="accordionOfficerGuide">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOfficerGuide">
        <button class="accordion-button collapsed" type="button"
                data-bs-toggle="collapse" data-bs-target="#collapseOfficerGuide"
                aria-expanded="false" aria-controls="collapseOfficerGuide">
          Full Guide <muted class="ps-1">&mdash; Made by commander <cite title="[#29][29X]Tootsie">[#29][29X]Tootsie</cite></muted>
        </button>
      </h2>
      <div id="collapseOfficerGuide" class="accordion-collapse collapse"
           aria-labelledby="headingOfficerGuide"
           data-bs-parent="#accordionOfficerGuide">
        <div class="accordion-body">
          <blockquote class="blockquote">
            <p>
              Hello everyone!<br />
              I was asked to write an outline of possible officer's combos, so I will do the best that I can to my
              knowledge. Basic troops advantage are: Infantry beats Anti Tank, Anti Tank beats Tank, and Tank beats
              Infantry - meaning all 3 types are designed to beat one another - just remember them when you setup your
              troops for battles.
            </p>
            <p>
              <u>All Open Field</u><br/>
              We should always use ONLY 1 TYPE of troop. For example, all Tanks for Tank Officers, Tank Destroyers for
              Anti Tank Officers, and Infantry for Infantry Officers. Why one type you ask? Simple, by using only 1
              type of troop for Open Field, we not only help the troops move faster on the map, we actually making our
              Officers stronger by getting their units' boost from their talents. Furthermore, we should only use Tank
              Officers, Anti Tank Officers, or Infantry Officers for Open Field Battles, and yes, it is very important
              to always put a 5 stars as a 2nd Officer to utilize her talents. Furthermore, please remember to ALWAYS
              USE SIMILAR Officers' TALENTStogether, for example, Tank pair with Tank, Anti Tank pair with Anti Tank,
              and please DO NOT PAIR: Tank with Anti Tank, or Tank with Infantry, etc - it will only make our troops
              slower and less effective.
            </p>
            <p>
              <u>Base Rally, City Rally, or Solo Base Hit</u><br/>
              We should always use 3 or MORE TYPES of troops, meaning Tank, Tank Destroyer, and Infantry (Artillery
              suck, use them only to farm) because when we put them together our Officers will benefit from multi
              troops boost from their talents, thus, making them more effective - but if we only use 1 type of troop
              for this type of attack, then our Officers will not get the units' boost from their talents, thus,
              making them less effectiveness.
            </p>
            <p>
              For <u>Invaders or Behemoths</u>, we should use Tank Officers with pure tanks (tank destroyers are NOT
              tanks!!!) or Generals (mix of tank, anti-tank, and infantry).
            </p>
            <hr />
            <p>
              It is highly recommended that we use the correct Officer's Talents for specific battles that they were
              designed for to maximize their talents, otherwise, they are less effective, thus, not good at all. Also,
              as I mentioned in my previous mail that we MUST ALWAYS PAIR SIMILAR talents Officers to maximize their
              effectiveness. Tank with Tank..Infantry with Infantry, General with General, Seige with Seige, etc, but
              an exception is General with Seige (for Rally Base Attack)
            </p>
            <p>
              <u>General talents</u> are for RALLY BASE/CITY ATTACKS only - Please don't use General Talents for Open
              Field - while they look good that they add extra troops into an Open Field Battles, but it's Open Field
              and not Base Attack or City Attack, thus, they won't get that extra troops boost.
            </p>
            <p>
              <u>Seige talents</u> are for BASE ATTACK ONLY - please do not use Seige Talents for Open Field, for they
              will not get their troops boost from their talents, because Open Field is not a Base Attack.
            </p>
            <p>
              <u>Garrison talents</u> are for DEFENDING YOUR BASE Only - please do not use Garrison Talents for
              anything else, as they won't get their troops boost if not using as a Garrison Officer. Garrison
              Officers should remain in your base 24/7 - unless, you need that officer for something else - you should
              do a Talent Reset before and after if you need to use for these situations.
            </p>
          </blockquote>
        </div>
      </div>
    </div>
  </div>

  <h4 class="mt-3 text-decoration-underline">Officer Combos</h4>

  <dl>
    <dt>Tank <muted>(tank IS BY FAR the best all-round troop combination)</muted></dt>
    <dd>
      <ul>
        <li>Katherine + Grace</li>
        <li>Katherine + Natalia</li>
        <li>Katherine + Linda</li>
        <li>Katherine + Abigail</li>
        <li>Natalia + Grace</li>
        <li>Linda + Abigal <muted>(for f2p players)</muted></li>
        <li>Grace + Linda <muted>(for f2p players)</muted></li>
      </ul>
    </dd>

    <dt>Anti-Tanks / Tank Destroyers</dt>
    <dd>
      <ul>
        <li>Lilith + Jessica</li>
        <li>Lilith + Diana</li>
        <li>Lilith + Maria</li>
        <li>Lilith + Monica</li>
        <li>Jessica + Maria <muted>(for f2p players)</muted></li>
        <li>Ling + Monica <muted>(for f2p players)</muted></li>
        <li>Ling + Maria <muted>(for f2p players)</muted></li>
      </ul>
    </dd>

    <dt>Infantry</dt>
    <dd>
      <ul>
        <li>Angelica + Loubna</li>
        <li>Angelica + Sakura</li>
        <li>Angelica + Riven <muted>(for f2p players)</muted></li>
        <li>Angelica + Evelyn <muted>(for f2p players)</muted></li>
      </ul>
    </dd>
  </dl>
  <hr/>
  <dl>
    <dt>Rally <muted>(General Talents)</muted></dt>
    <dd>
      <ul>
        <li>Chloe + Doireann</li>
        <li>Chloe + Ophelia</li>
        <li>Chloe + Heleny</li>
        <li>Chloe + Angel</li>
      </ul>
    </dd>

    <dt>Base Attack <muted>(Siege Talents)</muted></dt>
    <dd>
      <ul>
        <li>Chloe + Doireann</li>
        <li>Chloe + Ophelia</li>
        <li>Chloe + Sakura</li>
        <li>Chloe + Diana</li>
        <li>Chloe + Heleny</li>
        <li>Chloe + Riven</li>
      </ul>
    </dd>

    <dt>Base Defense <muted>(Garrison Talents)</muted></dt>
    <dd>
      <ul>
        <li>Grace + Jessica</li>
        <li>Grace + Maria</li>
        <li>Grace + Natalia</li>
        <li>Grace + Ophelia</li>
        <li>Grace + Heleny</li>
        <li>Grace + Abigail</li>
      </ul>
    </dd>

    <dt>Invaders <muted>(Jungler Talents)</muted></dt>
    <dd>
      <ul>
        <li>Katherine + Linda</li>
        <li>Katherine + Dorothea</li>
        <li>Lilith + Dorothea</li>
        <li>Linda + Dorothea <muted>(for f2p players)</muted></li>
      </ul>
    </dd>
  </dl>
</template>

<script>
import { defineComponent } from 'vue'
import Muted from '@/components/muted'

export default defineComponent({
  name: "Officers",
  components: { Muted }
})
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/mixins';

.accordion-header small {
  @include font-size(75%);
}
.blockquote {
  @include font-size(0.9rem);
}
</style>